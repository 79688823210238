$medium: 768px;
$small: 500px;

.nav-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    height: 100%;
    position: relative;
    width: var(--container-width);
    margin-inline: auto;

    .btn-close-nav {
        position: absolute;
        right: var(--padding);
        top: 0;
        color: var(--clr-n-900);
        font-size: 1.5rem;
        &:hover {
            transform: scale(1);
        }
    }

    li {
        display: inline-block;
        cursor: pointer;
        transition: color .1s var(--transition); 
        align-self: flex-start;
        font-size: 2.5rem;
        font-weight: 900;

        @media (max-width: $medium) {
            font-size: 2rem;
            font-weight: 700;
        }
        @media (max-width: $small) {
            font-size: 1.5rem;
            font-weight: 700;
        }

        &:hover {
            color: var(--clr-accent);
            img {
                top: 0%;
                opacity: 1;
            }
        }

        img {
            position: absolute;
            max-width: 50%;
            height: 100%;
            top: 20%;
            right: 0;
            opacity: 0;
            pointer-events: none;
            user-select: none;
            transition: all .5s var(--transition); 
            @media (max-width: $medium) {
                display: none;
            }
        }
    }
}