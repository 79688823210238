$lg: 1000px;
$medium: 768px;
$small: 500px;

.arrival {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .arrival-container {
        --grid-item-width: 300px;
        --grid-item-height: 400px;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @media screen and (max-width: $lg) {
            --grid-item-width: 250px;
            --grid-item-height: 300px;
        }


        .arrival-carousel {
            display: flex;
            gap: 2rem;
            overflow-x: hidden;


            @media screen and (max-width: $medium) {
                flex-direction: column;
            }
    

            .carousel-btns {
                display: flex;
                gap: 2rem;
                align-items: flex-start;
                min-width: var(--grid-item-width);
                background-color: var(--clr-n-100);
                position: relative;
                z-index: 10;

                .btn-carousel {
                    border: 1px solid var(--clr-n-200);
                    border-radius: 50%;
                    display: flex;
                    font-size: 1.5rem;
                    padding: 1rem;
                    color: var(--clr-n-900);

                    
                    @media screen and (max-width: $small) {
                        font-size: 1rem;
                    }

                    .icon {
                        pointer-events: none;
                    }

                    &:hover, &:focus {
                        border-color: var(--clr-n-700);
                    }
                }
            }

            .carousel-grid {
                --slide-index: 0;
                --slide-width: calc(var(--grid-item-width) + 2rem);
                --slides-in-view: 1;
                display: grid;
                grid-auto-flow: column;
                gap: 2rem;
                transform: translateX(calc(-1 * var(--slide-index) * var(--slide-width)));
                transition: transform .5s var(--transition);

                .grid-item {
                    width: var(--grid-item-width);
                    height: var(--grid-item-height);
                    position: relative;
                    overflow: hidden;

                    img {
                        height: 100%;
                        border-radius: var(--border-3);
                    }

                    
                    &:hover {
                        .icons {
                            bottom: 5%;
                        }
                    }

                    .icons {
                        position: absolute;
                        bottom: -100%;
                        left: 0;
                        display: flex;
                        gap: 1rem;
                        padding: 1rem;
                        background-color: rgb(0, 0, 0, 0.5);
                        width: 100%;
                        transition: .5s ease-in-out;

                        @media (max-width: $small) {
                            bottom: 5%;
                        }
    

                        .icon {
                            display: inline-block;
                            cursor: pointer;
                            font-size: 1.5rem;
                        }
                        .view {
                            color: var(--clr-accent);
                            text-transform: uppercase;
                            font-weight: 600;
                            cursor: pointer;
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
}