$medium: 768px;
$small: 500px;

.footer {
    padding-block: 5rem;
    background-color: var(--clr-n-100);
    color: var(--clr-n-900);
    border-top: 1px solid var(--clr-n-700);

    .footer-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(500px,1fr));
        gap: 2rem;

        @media (max-width: $medium) {
            grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
        }
        @media (max-width: $small) {
            grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
        }

        .sign-up {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            
            p {
                font-size: 1.2rem;
            }
            h2 {
                font-size: 2rem;
                text-transform: uppercase;
            }

            .input-box {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                input {
                    max-width: 500px;
                    height: 50px;
                    padding-inline: 1rem;
                    font-family: inherit;
                    font-size: 1rem;
                    border: 0;
                    outline: none;

                    
                    @media (max-width: $medium) {
                        height: 40px;
                        padding-inline: 0.5rem;
                        font-size: 0.8rem;
                        max-width: 100%;
                    }

                }
                .btn-sign {
                    align-self: flex-start;
                    padding: 0.5rem 1rem;
                    border: 2px solid var(--clr-n-800);
                    color: var(--clr-n-900);
                }
            }
        }
        .footer-links {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
            gap: 2rem;

            ul {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                p {
                    font-size: 1.5rem;
                    text-transform: uppercase;
                }

                li {
                    display: inline-block;
                    cursor: pointer;
                    align-self: flex-start;
                    transition: color .1s var(--transition);

                    &:hover {
                        color: var(--clr-accent);
                    }
                }
            }
        }
    }
}