$medium: 768px;
$small: 500px;

.hero {
    width: 100%;

    .hero-text {
        display: flex;
        flex-direction: column;
        padding-block: 5rem 3rem;
        text-align: center;

        h1 {
            font-size: var(--font-h1);
            line-height: .9;
            font-weight: 900;
            text-transform: uppercase;
        }

        p {
            font-size: 1.5rem;
            font-weight: 900;
            color: var(--clr-n-400);
            max-width: 60%;
            margin-inline: auto;
            line-height: 1;
            padding-block: 1rem;

            @media (max-width: $medium) {
                max-width: 100%;
                font-size: 1.3rem;
            }

            @media (max-width: $small) {
                font-size: 1.1rem;
            }
        }
        .btn-hero {
            background-color: var(--clr-accent);
            color: var(--clr-n-900);
            font-size: 1.1rem;
            font-weight: 700;
            align-self: center;
            padding: 0.75rem 1.25rem;

            &:focus {
                outline: 2px solid var(--clr-n-400);
                outline-offset: 1px;
            }
        }
    }
    .video-container {
        width: 100%;
        height: var(--container-height);
        @media (max-width: $small) {
            height: min(60vh, 600px);
        }
        video {
            height: 100%;
            border-radius: var(--border-1);
        }
    }
}