$small: 500px;

.show {
    min-height: calc(100vh - var(--header));
    padding-bottom: 5rem;
    .container {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .results {
            display: flex;
            flex-direction: column;
            gap: .15rem;
            p {
                font-size: 0.9rem;
                color: var(--clr-n-400);
            }
            h2 {
                line-height: 1;
            }
        }

        .filters-grid {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &>div {
                display: flex;
                gap: 0.5rem;
                align-items: center;
            }
            .filter {
                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .sort {
                select {
                    background: none;
                    padding: 0.25rem 0.5rem;
                    border: 0;
                    outline: none;
                    border: 1px solid var(--clr-n-400);
                    font-family: inherit;
                }
            }
        }
        .items-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            gap: 2rem;

            .item {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;

                &:hover {
                    .icons {
                        top: 5%;
                    }
                }
                                
                h5 {
                    font-size: 1.2rem;
                    text-transform: capitalize;
                }
                p {
                    color: var(--clr-n-400);
                    font-weight: 700;
                }
                img {
                    height: 300px;
                    border-radius: var(--border-4);
                }

                .icons {
                    position: absolute;
                    top: -20%;
                    left: 0;
                    display: flex;
                    gap: 1rem;
                    padding: 1rem;
                    background-color: rgb(0, 0, 0, 0.5);
                    width: 100%;
                    transition: .3s ease-in-out;

                    @media (max-width: $small) {
                        top: 5%;
                    }

                    .icon {
                        display: inline-block;
                        cursor: pointer;
                        font-size: 1.2rem;
                        color: var(--clr-n-900);
                    }
                    .view {
                        color: var(--clr-accent);
                        text-transform: uppercase;
                        font-weight: 600;
                        cursor: pointer;
                        margin-left: auto;
                        font-size: .9rem;
                    }
                }
            }
        }
    }
}