$medium: 768px;
$small: 500px;

.best-seller {
    margin-top: 5rem;

    .seller-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;


        p {
            font-size: 1.2rem;
            font-weight: 500;
            max-width: 500px;
            line-height: 1;
            color: var(--clr-n-700);
        }
        
        .seller-slider {
            display: flex;
            flex-direction: column;

            .seller-grid {
                position: relative;
                display: grid;
                grid-auto-flow: column;
                gap: 2rem;

                @media (max-width: $medium) {
                    grid-template-columns: repeat(2,1fr);
                    grid-auto-flow: initial;
                }

                @media (max-width: $small) {
                    gap: 1rem;
                }

                .grid-item {
                    width: 100%;
                    height: min(50vh, 500px);

                    @media (max-width: $medium) {
                        height: min(40vh, 400px);
                    }
                    @media (max-width: $small) {
                        height: min(25vh, 250px);
                    }

                    &:first-child {
                        height: min(80vh, 800px);
                        @media (max-width: $medium) {
                            height: min(60vh, 600px);
                        }
                        @media (max-width: $small) {
                            height: min(40vh, 400px);
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: var(--border-3);
                    }
                }
                .btn-more {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    border: 2px solid var(--clr-n-800);
                    font-size: 1rem;
                    padding: 0.5rem 1rem;
                    color: var(--clr-n-900);
                }
            }
        }
    }
}