$medium: 768px;
$small: 500px;

.socials {
    padding-bottom: 5rem;

    .socials-container {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .socials-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(500px,1fr));
            gap: 2rem;

            @media (max-width: $medium) {
                grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
            }
            @media (max-width: $small) {
                grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
            }

            .grid-item {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                img {
                    height: min(70vh, 700px);
                    border-radius: var(--border-3);
                    @media (max-width: $small) {
                        height: min(50vh, 500px);
                    }
                }
                .date {
                    font-size: .8rem;
                    color: var(--clr-n-700);
                }
                .title {
                    font-size: 2rem;
                    line-height: 1;
                    text-transform: uppercase;
                }

                .desc {
                    line-height: 1.1;
                    color: var(--clr-n-800);
                }
            }
        }
    }
}