$lg: 1000px;
$medium: 768px;
$small: 500px;

.cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 0.5rem;
    border: 1px solid transparent;
    border-top-color: var(--clr-n-700);
    border-bottom-color: var(--clr-n-700);

    h1 {
        font-size: 2.5rem;
        text-transform: uppercase;
        @media (max-width: $medium) {
            font-size: 2rem;
        }
        @media (max-width: $small) {
            font-size: 1.5rem;
        }
    }

    .btn-close {
        font-size: 1.5rem;
        &:hover {
            transform: scale(1);
        }
    }
}
.cart-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;


    .order-grid {
        display: grid;
        gap: 1.5rem;

        .order-item {
            display: flex;
            gap: 0.5rem;
            height: 150px;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid var(--clr-n-700);


            .img-container {
                max-width: 150px;
                width: 100%;
                img {
                    height: 100%;
                    border-radius: var(--border-4);
                }
            }
            .center {
                display: flex;
                flex-direction: column;
                gap: .5rem;
                flex-grow: 1;

                h5 {
                    font-size: 1.5rem;
                    text-transform: capitalize;
                    flex-grow: 1;
                    @media (max-width: $small) {
                        font-size: 1rem;
                    }
        

                }

                p {
                    font-size: .8rem;
                    span {
                        margin-left: .25rem;
                        &.oreder-item__color {
                            border: 1px solid;
                            padding: .25rem;
                            border-radius: 3px;
                        }
                    }
                }
                span {
                    font-size: .9rem;
                }
                .quantity {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    @media (max-width: $small) {
                       gap: .5rem;
                    }

                    .btn-cart-quantity {
                        font-size: 1.2rem;
                        padding: .25rem;

                        &:hover {
                            background-color: hsl(180, 0%, 95%);
                        }
                    }
                }
            }
            .right {
                display: flex;
                flex-direction: column;
                h5 {
                    font-size: 1.5rem;
                    text-transform: capitalize;
                    @media (max-width: $small) {
                        font-size: 1rem;
                    }
                }
                .btn-cart-remove {
                    text-transform: capitalize;
                    font-weight: 700;
                    color: var(--clr-n-400);
                    margin-top: 2rem;
                    border: 1px solid var(--clr-n-700);
                    padding: .35rem .15rem;
                } 
            }
        }
    }
}
.cart-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block: 1rem;
        border-top: 1px solid var(--clr-n-700);
        border-bottom: 1px solid var(--clr-n-700);
        font-size: 1.3rem;
        p {
            text-transform: uppercase;
            font-weight: 700;
        }
    }
    .btn-checkout {
        padding: 1rem;
        border: 2px solid var(--clr-n-200);
        font-size: 1.2rem;
        font-weight: 700;
        color: var(--clr-n-900);
        background-color: var(--clr-accent);

        @media (max-width: $medium) {
            font-size: 1rem;
        }
        @media (max-width: $small) {
            font-size: .8rem;
        }
    }
}

.cart-detail-container {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 35%;
    background-color: var(--clr-n-900);
    color: var(--clr-n-200);
    transform: translateX(100%);
    transition: transform .5s var(--transition);
    z-index: 100;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: auto;
    border-left: 1px solid var(--clr-n-700);

    
    @media (max-width: $lg) {
        width: 50%;
    }
    @media (max-width: $medium) {
        width: 70%;
        padding: 2rem;
    }

    @media (max-width: $small) {
        width: 100%;
        padding: 1rem;
    }

    &.open {
        transform: translateX(0%);
    }

    .cart-detail_content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-grow: 1;

        &>p {
            font-size: .9rem;
            span {
                font-weight: 600;
            }
        }

        .top {
            display: flex;
            gap: 2rem;

            .item-img {
                max-width: 200px;
                height: 200px;

                img {
                    height: 100%;
                    border-radius: 3px;
                }
            }
            .details {
                p {
                    font-size: 1.1rem;
                    font-weight: 600;
                    text-transform: uppercase;

                    &.price {
                        color: var(--clr-n-400);
                        font-weight: 400;
                        padding-top: .5rem;
                    } 
                }
            }

        }

        .middle {
            display: flex;
            gap: .5rem;
            flex-wrap: wrap;

            .cart_input-readonly {
                border: 1px solid var(--clr-n-700);
                outline: none;
                width: auto;
                cursor: pointer;
                padding: .65rem .75rem;
                border-radius: 3px;
                background: none;
                font-weight: 600;
                font-size: .9rem;

                &.selected {
                    outline: 2px solid var(--clr-accent);
                    outline-offset: 1px;
                }
            }
        }
    }
}