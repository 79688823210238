$lg: 1000px;
$medium: 768px;
$small: 500px;

.header {
    height: var(--header);
    border-bottom: 1px solid var(--clr-n-700);
    overflow-x: hidden;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .left-nav, .right-nav {
            display: flex;
            height: 100%;
            align-items: center;
            gap: 1rem;
    
            &>div {
                font-size: 1.2rem;
                cursor: pointer;
                @media (max-width: $small) {
                    font-size: 1rem;
                }
            }

            .register-icon {
                outline: none;
                background: none;
                border: 1px solid var(--clr-n-100);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: .5rem;
                border-radius: 50%;

                font-size: 1.2rem;
                cursor: pointer;
                @media (max-width: $small) {
                    font-size: 1rem;
                }

                &:focus, &:active {
                    outline: 2px solid var(--clr-n-400);
                    outline-offset: 1px;
                    color: var(--clr-accent);
                    border-color: var(--clr-accent);
                }

                &.cart-icon {
                    position: relative;

                    &::before {
                        content: attr(data-cart-quantity);
                        position: absolute;
                        bottom: -0.25rem;
                        right: -.25rem;
                        color: var(--clr-n-900);
                        font-size: .75rem;
                        padding: .25rem;
                        width: 1.25rem;
                        height: 1.25rem;
                        background-color: red;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        font-family: inherit;
                    }
                }
            }
        }
    
        .logo {
            h1 {
                font-size: 2rem;
                font-weight: 900;
                letter-spacing: 2px;
                text-transform: uppercase;
                color: var(--clr-n-200);
    
                @media (max-width: $medium) {
                    font-size: 1.7rem;
                    font-weight: 700;
                }
    
                @media (max-width: $small) {
                    font-size: 1.2rem;
                    letter-spacing: 1px;
                }
            }
        }

        .header__cart-container {
            position: fixed;
            top: 0;
            right: 0;
            height: 100vh;
            width: 40%;
            background-color: var(--clr-n-900);
            color: var(--clr-n-200);
            transform: translateX(100%);
            transition: transform .5s var(--transition);
            z-index: 100;
            padding: 3rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            overflow-y: auto;

            @media (max-width: $lg) {
                width: 60%;
            }
            @media (max-width: $medium) {
                width: 80%;
                padding: 2rem;
            }
        
            @media (max-width: $small) {
                width: 100%;
                padding: 1rem;
            }
        
            &.open {
                transform: translateX(0%);
            }
        }
    
        .nav {
            position: absolute;
            top: 0;
            padding: var(--padding);
            padding-top: var(--header);
            left: 0;
            height: 100vh;
            width: 100%;
            background-color: var(--clr-n-100);
            color: var(--clr-n-900);
            transform: translateX(-100vw);
            transition: transform .5s var(--transition);
            z-index: 100;
    
            &.open {
                transform: translateX(0%);
            }
        }
    }
}